"use strict";

// Initialization
KTUtil.ready(function() {
	console.log('panel-notificaciones');
	if (typeof KTLayoutQuickPanel!== 'undefined') {
    KTLayoutQuickPanel.init('current_user_widget_panel');
	}

	$('#notificacion-panel').on('loadUrlContent', function() {
		
		let c = $('#notificacion-panel').find('a.no-leido').length
		if (c > 0) {
			$('#notificacion-counter').text(c).addClass('bg-danger').removeClass('bg-muted');
		} else {
			$('#notificacion-counter').text('0').removeClass('bg-danger').addClass('bg-muted');
		}

	});

});