console.log('js-tarea-tomar');
$(document).ready(function() {
    
    if ($('.btnTomarTareaJs').length) {
        //$('.btnTomarTareaJs').each(
            //function(index) {
            $('.btnTomarTareaJs').click( function(e){
                e.preventDefault();
                console.log($(this).prop('href'));
                $.ajax({
                  url: $(this).prop('href'),
                  
                }).done(function() {
                  $('.btnTomarTareaJs').parent().remove();
                  console.log('done tomar action');
                }); 	
            });
            //}
        //);
    }
});