console.log('archivo-tipo');


var archivoTipo = function() {
	var fields = '.field_archivo_tipo';
	var row_view_poseedor = '#row_archivo_possedor';

	function loadding() {
		console.log("asd");
	}

	this.loadding();
}


$(document).ready(function() {
	$('#kt_content').on('change', '.field_archivo_tipo', function() {
		if ($('.field_archivo_tipo').val() == '3') {
			$('#row_archivo_possedor').removeClass('d-none');
		} else {
			$('#row_archivo_possedor').addClass('d-none');
		}
	}).trigger('change');

	$('#kt_content').on('change', '.field_archivo_poseedor', function() {
		if ($('.field_archivo_poseedor').val() == 'archive_other_user') {
			$('#row_archivo_other').removeClass('d-none');
		} else {
			$('#row_archivo_other').addClass('d-none');
		}
	}).trigger('change');

	setTimeout(() => {
		$('.field_archivo_tipo').trigger('change');
		$('.field_archivo_poseedor').trigger('change');
	}, 300);

});
